import { useMemo, useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next';
import type { Moment } from 'moment';
import moment from 'moment';
import "moment/locale/zh-cn";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from './from-gatsby-theme-material-ui/icon-button';
import { extractLastSegment } from '../utils/url/url';
import { convLngCdCanonical } from '../utils/i18n/conv';

export default function GalleryLinkCalender(
  { currentPath, language, calenderHeader }
    : { currentPath: string, language: string, calenderHeader: string }) {
  const data = useStaticQuery<Queries.GalleryLinkDataQuery>(graphql`
    query GalleryLinkData{
      allCloudinaryFolder(sort: {fields: dateFromFolderName}) {
        nodes {
          dateFromFolderName
          foldername
        }
      }
    }
  `);

  const foldernameArray = useMemo(() => {
    return data.allCloudinaryFolder.nodes.map(node => node.foldername);
  }, []);

  const currentPageDate = moment(extractLastSegment(currentPath || ""), "YYYYMMDD")
  const [value, setValue] = useState<Moment | null>(currentPageDate);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const convertedLanguage = convLngCdCanonical(language)

  const { changeLanguage } = useI18next()

  useEffect(() => {
    setValue(currentPageDate)
  }, [currentPath])


  // necessary: for without this line LocalizationProvider doesnt change weekday localization
  moment.locale(convertedLanguage);
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={convertedLanguage}
    >
      <DatePicker
        value={value}
        open={open}
        showToolbar={true}
        closeOnSelect={true}
        disableOpenPicker={true}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        onAccept={(acceptedValue) => {
          if (!acceptedValue) return;
          if (!foldernameArray.includes(acceptedValue.format("YYYYMMDD"))) return;
          changeLanguage(language, `/gallery/${acceptedValue.format("YYYYMMDD")}`);
        }}
        onClose={() => {
          handleClose();
        }}
        renderInput={({ inputRef, InputProps, }) => (
          <Box ref={inputRef} >
            <IconButton onClick={handleOpen} sx={{ color: "#fff" }}>
              <EventIcon></EventIcon>
            </IconButton>
            {InputProps?.endAdornment}
          </Box>
        )}
        ToolbarComponent={() => (
          <Box p="6px">
            <Typography variant='subtitle2' align='center' fontSize="1.2em">{calenderHeader}</Typography>
          </Box>
        )
        }
        componentsProps={{
          actionBar: {
            actions: (variant) => (variant === 'desktop' ? [] : ['cancel']),
          },
        }}
        renderDay={(day, _value, DayComponentProps) => {
          const isContained =
            !DayComponentProps.outsideCurrentMonth &&
            foldernameArray.includes(day.format("YYYYMMDD"));
          const cmp = isContained
            ? (
              <PickersDay
                {...DayComponentProps}
                sx={{ color: "blue" }}
              />
            )
            : (
              <PickersDay {...DayComponentProps} disabled={true} />
            );
          return cmp;
        }}
      />
    </LocalizationProvider>

  );
}
