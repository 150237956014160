import { useState } from 'react'
import LanguageIcon from '@mui/icons-material/Language';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from './from-gatsby-theme-material-ui/icon-button';
import { LinkForI18n as Link } from './from-gatsby-theme-material-ui/link';
import { verboseNationalNames } from '../utils/i18n/conv';

function getVerboseNationalName(code: string) {
    const found = verboseNationalNames.find(item => item.code === code);
    return found ? found.verbose : verboseNationalNames[0].verbose;

}

export default ({ languages, originalPath }: { languages: string[], originalPath: string }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-controls={open ? 'language-selection-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: "#fff" }}
            >
                <LanguageIcon></LanguageIcon>
            </IconButton>
            <Menu
                aria-labelledby="language-selection-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {languages.map((lng) => (
                    <MenuItem onClick={handleClose} key={lng}>
                        <Link to={originalPath} language={lng} underline="none">
                            {getVerboseNationalName(lng)}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
