import { GatsbyBrowser } from "gatsby"
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from './components/layout';
import theme from './components/theme';

export const wrapWithProvider: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {element}
            </ThemeProvider>
        </>
    )
}

export const wrapWithLayout: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
    return <Layout pageProps={props}>{element}</Layout>
}
