import React from 'react';
// import { GatsbyLinkProps } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';

export type i18nLinkProps=React.ComponentProps<typeof Link>

interface ALinkProps extends Omit<i18nLinkProps, 'to'> {
    href: string;
}

const ALink: React.FC<ALinkProps> = ({
    href,
    children,
    innerRef,
    ...other
}) => (
    <a href={href} ref={innerRef} {...other}>
        {children}
    </a>
);

export const GatsbyLinkForI18n = React.forwardRef(
    (
        props: Omit<i18nLinkProps, 'ref'>,
        ref: React.Ref<HTMLAnchorElement>,
    ) => {
        const { to, activeClassName, partiallyActive, ...other } = props;
        const internal = /^\/(?!\/)/.test(to);

        // Use Gatsby Link for internal links, and <a> for others
        if (internal) {
            const file = /\.[0-9a-z]+$/i.test(to);

            if (file) {
                return <ALink href={to} innerRef={ref} {...other} />;
            }
            return (
                <Link
                    to={to}
                    activeClassName={activeClassName}
                    partiallyActive={partiallyActive}
                    innerRef={ref}
                    {...other}
                />
            );
        }
        return <ALink href={to} innerRef={ref} {...other} />;
    },
);

GatsbyLinkForI18n.displayName = 'Link';