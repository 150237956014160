const languageMapper = {
    "zn": "zh-cn",
}

export const verboseNationalNames: { code: string, verbose: string }[] = [
    { code: "en", verbose: "English" },
    { code: "zh", verbose: "Chinese" },
]

export function convLngCdCanonical(lngCodeRaw: string) {
    const converted = languageMapper[lngCodeRaw];
    return converted ? converted : lngCodeRaw;
}