import React from 'react';
import MuiLink, { LinkProps } from '@mui/material/Link';

import { GatsbyLink } from './glink';
import { GatsbyLinkForI18n } from './glink-for-i18n';
import type {i18nLinkProps } from './glink-for-i18n';

const Link = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & { to?: string }
>((props, ref) => {
  const { to } = props;
  return to ? (
    <MuiLink ref={ref} component={GatsbyLink} to={to} {...props} />
  ) : (
    <MuiLink ref={ref} {...props} />
  );
});

export const LinkForI18n = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & { to?: string } & {language?:string}
>((props, ref) => {
  const { to } = props;
  return to ? (
    <MuiLink ref={ref} component={GatsbyLinkForI18n} to={to} {...props} />
  ) : (
    <MuiLink ref={ref} {...props} />
  );
});

export default Link